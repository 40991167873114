import request from "@utils/request";

//免费扩容(邀请好友)
export const invitation = (params)=>{
    return request({
        url:'index/center/invitation',
        method:'post',
        params,
    })
}

