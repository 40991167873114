<template>
  <div class='container' >
    <h5>免费扩容</h5>
    <div class="content"   v-if="freeExpansionObj.invitation_set.enabled_status == 1 ">
        <div class="invite">
            <div class="invite-left">
                <img src="@assets/img/profile/icon-invite.png"/>
                <div class="invite-content">
                    <p>邀请{{freeExpansionObj.invitation_set.member_num}}名用户（{{freeExpansionObj.invite_num}}/{{freeExpansionObj.invitation_set.member_num}}）</p>
                    <span>+{{freeExpansionObj.invitation_set.show_storage_size}}百年云空间+{{freeExpansionObj.invitation_set.show_flow_size}}流量</span>
                </div>
            </div>
            <div class="copy"
                    v-clipboard:copy="freeExpansionObj.invite_url"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError">复制</div>
        </div>
        <div class="tip" v-html="freeExpansionObj.news.content" ></div>
    </div>
  </div>
</template>

<script>
import {invitation} from "@assets/api/freeExpansion";

export default {
  name:'',
  components: {},

  data () {
    return {
        link:'https://www.bilibili.com',
        freeExpansionObj:{
          invitation_set:{},
          invite_num:'',
          invite_url:'',
          news:{},
        },
    };
  },

  computed: {},

  created(){

  },
  mounted() {
    this.getfreeExpansionData()
  },

  methods: {

    getfreeExpansionData(){
      let params = {
        url:'/#/home?id='
      }
      invitation(params).then(res=>{
        console.log('res',res)
        this.freeExpansionObj = res.data
      })
    },

    copySuccess(){
        this.$Message.success('复制成功')
    },
    copyError(){
        this.$Message.warning('复制失败')
    }
  },

}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/account/freeExpansion.less';
</style>